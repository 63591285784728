footer {
  padding-top: 50px;
  padding-bottom: 50px;

  .container {
    width: 100%;
    max-width: calc($content-max-width + (2 * $site-padding));
    padding-left: $site-padding;
    padding-right: $site-padding;
  }

  .col-md-2 {
    width: auto;
  }

  h2 {
    margin-bottom: 15px;
    font-family: $font-dm-sans;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    text-transform: uppercase;
  }

  a.footer-contact {
    display: block;
    margin-bottom: 15px;
    color: $color-green;
    font-family: $font-dm-sans;
    text-decoration: underline;
    &:hover {
      color: $color-red;
    }
  }

  .social-icons {
    li {
      margin-right: 30px;
      box-shadow: none;

      a {
        background-color: $color-black;
        .fa:before {
          color: $color-white;
        }
      }
      &:hover {
        &.social-icons-facebook a {
          background-color: #3b5998;
        }
        &.social-icons-twitter a {
          background-color: #1DA1F2;
        }
        &.social-icons-instagram a {
          background-color: #8a3ab9;
        }
      }
    }
  }

  .col-md-5 {
    width: auto;
    margin-top: 30px;
    padding-left: 110px;
    background-image: url('../images/seal.png');
    background-repeat: no-repeat;
    float: left;

    @include breakpoint($screen-tablet-wide + 71px) {
      margin-top: 0;
      float: right;
    }
  }

  .hbu {
    &.top {
      margin-bottom: 6px;
      font-size: 15px;
      line-height: 1.5;
    }
    &.bottom {
      font-size: 25px;
      font-weight: $font-weight-bold;
      line-height: 1.2;
    }
    a {
      color: $color-black;
    }
  }
}

.footer-copyright .container > .row {
  margin-left: 0;
  margin-right: 0;
}

.region-footer-bottom {
  margin-bottom: 0;
  p {
    margin-top: 20px;
    margin-bottom: 0;
    color: $color-black;
    font-family: $font-dm-sans;
    font-size: 12px;
    line-height: 1.5;
    a {
      color: $color-black;
    }
  }
}
