#header {
  .header-nav-main nav > ul > li {
    margin-left: 0;

    a {
      padding: 5px 10px;
      color: $color-black;
      font-family: $font-dm-sans;
      font-size: 16px;
      line-height: 1.5;
      text-decoration: underline;
      &.dropdown-toggle:after {
        content: none;
      }
    }

    &:hover a {
      background-color: transparent;
      color: $color-red;
    }

    &:last-child > a {
      @include button;
      margin-left: 10px;
      padding: 5px 50px 5px 30px;
      font-size: 16px;
      font-weight: $font-weight-normal;
      line-height: 1.5;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .header-body {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
}
