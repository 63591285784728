.paragraphs-item-image-and-text {
  color: $color-black;

  > .content {
    display: flex;
    max-width: calc($content-max-width + (2 * $site-padding));
    margin: 0 auto;
    padding: 100px $site-padding;
    align-items: center;
    flex-direction: column-reverse;

    @include breakpoint($screen-tablet) {
      flex-direction: row;
    }
  }

  .field-name-field-paragraphs-image {
    max-width: 350px;
    margin: 30px auto 0px;

    @include breakpoint($screen-tablet) {
      max-width: auto;
      margin: 0 88px 0 0;
      flex-basis: 35%;
    }
  }

  .group-right-column {
    @include breakpoint($screen-tablet) {
      flex-basis: calc(65% - 88px);
    }
  }

  .field-name-field-paragraphs-title {
    display: inline-block;
    margin-bottom: 25px;
    border-bottom: 10px solid $color-orange;
    font-family: $font-montserrat;
    font-size: 36px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.01em;
    line-height: 1.3;
  }

  .field-name-field-paragraphs-content {
    font-family: $font-dm-sans;
    font-size: 22px;
    letter-spacing: 0.01em;
    line-height: 1.45;
  }
}
