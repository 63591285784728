.paragraphs-item-text-and-cta {
  background-color: $color-milk;
  color: $color-black;
  background-image: url('../images/background-pattern.png');
  background-repeat: no-repeat;
  background-position: calc(50% - 410px);
  background-size: auto calc(100% + 1px);

  > .content {
    max-width: calc($content-max-width + (2 * $site-padding));
    margin: 0 auto;
    padding: 100px $site-padding;

    * {
      max-width: 515px;
      margin-left: auto;
    }
  }

  .field-name-field-paragraphs-title {
    font-family: $font-montserrat;
    font-size: 24px;
    line-height: 1.3;
  }

  .field-name-field-paragraphs-content {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: $font-dm-sans;
    font-size: 18px;
    line-height: 1.5;
  }

  .field-name-field-paragraphs-cta a {
    @include button;
    display: inline-block;
  }
}
