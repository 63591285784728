.node-type-paragraphs-page {
  .page-header {
    display: none;
  }

  #content > .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }

  ul.links.inline {
    display: flex;
    margin-bottom: 0;
  }
}
