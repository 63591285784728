#logo {
  display: block;
}

#logo img {
  display: block;
  height: 54px !important;
  width: auto !important;
  top: auto !important;
}

.sticky-header-active #header .header-body {
  top: 0 !important;
}

#header .header-nav-main nav > ul > li {
  margin-left: 0;
}

#header .header-nav-main nav > ul > li a {
  color: #000;
  padding: 5px 10px;
  font-family: DM Sans;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: underline;
}

#header .header-nav-main nav > ul > li a.dropdown-toggle:after {
  content: none;
}

#header .header-nav-main nav > ul > li:hover a {
  color: #ab1f24;
  background-color: #0000;
}

#header .header-nav-main nav > ul > li:last-child > a {
  color: #fff;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: #0c8643;
  background-image: url("icon-button-arrow.56468076.svg");
  background-position: calc(100% - 30px);
  background-repeat: no-repeat;
  border-radius: 30px;
  margin-left: 10px;
  padding: 5px 50px 5px 30px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: underline;
}

#header .header-nav-main nav > ul > li:last-child > a:hover {
  background-color: #ab1f24;
  text-decoration: underline;
}

#header .header-body {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.node-type-paragraphs-page .page-header {
  display: none;
}

.node-type-paragraphs-page #content > .container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.node-type-paragraphs-page ul.links.inline {
  margin-bottom: 0;
  display: flex;
}

.paragraphs-item-hero {
  border-radius: 0 100px 0 0;
  position: relative;
  overflow: hidden;
}

@media (min-width: 781px) {
  .paragraphs-item-hero {
    border-radius: 0 200px 0 0;
  }

  .paragraphs-item-hero .field-name-field-hero-image:after {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("decoration-horizontal.d9222ac1.svg");
    background-position: calc(100% - 60px) calc(100% - 30px);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
  }
}

.paragraphs-item-hero .hero-bg-image {
  height: 250px;
  background-position-x: 100%;
}

@media (min-width: 781px) {
  .paragraphs-item-hero .hero-bg-image {
    height: 650px;
    background-position-x: center;
  }
}

.paragraphs-item-hero .hero-bg-image:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #f9d360;
  position: absolute;
}

@media (min-width: 781px) {
  .paragraphs-item-hero .hero-bg-image:before {
    z-index: auto;
    background-color: #0000;
    background-image: linear-gradient(45deg, #000, #0000);
  }
}

@media (min-width: 1200px) {
  .paragraphs-item-hero .hero-bg-image:before {
    content: none;
  }
}

.paragraphs-item-hero .group-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px;
  top: 30%;
  left: 0;
  right: 0;
}

@media (min-width: 781px) {
  .paragraphs-item-hero .group-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 70px;
    position: absolute;
  }
}

.paragraphs-item-hero .field-name-field-hero-title {
  max-width: 100%;
  color: #000;
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 30px;
  line-height: 1.3;
}

@media (min-width: 545px) {
  .paragraphs-item-hero .field-name-field-hero-title {
    font-size: 48px;
  }
}

@media (min-width: 781px) {
  .paragraphs-item-hero .field-name-field-hero-title {
    max-width: 690px;
    color: #fff;
  }
}

.paragraphs-item-hero .field-name-field-hero-cta a {
  color: #fff;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: #0c8643;
  background-image: url("icon-button-arrow.56468076.svg");
  background-position: calc(100% - 30px);
  background-repeat: no-repeat;
  border-radius: 30px;
  padding: 18px 50px 18px 30px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.paragraphs-item-hero .field-name-field-hero-cta a:hover {
  background-color: #ab1f24;
  text-decoration: none;
}

.paragraphs-item-image-and-text {
  color: #000;
}

.paragraphs-item-image-and-text > .content {
  max-width: 1260px;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 auto;
  padding: 100px 30px;
  display: flex;
}

@media (min-width: 781px) {
  .paragraphs-item-image-and-text > .content {
    flex-direction: row;
  }
}

.paragraphs-item-image-and-text .field-name-field-paragraphs-image {
  max-width: 350px;
  margin: 30px auto 0;
}

@media (min-width: 781px) {
  .paragraphs-item-image-and-text .field-name-field-paragraphs-image {
    max-width: auto;
    flex-basis: 35%;
    margin: 0 88px 0 0;
  }

  .paragraphs-item-image-and-text .group-right-column {
    flex-basis: calc(65% - 88px);
  }
}

.paragraphs-item-image-and-text .field-name-field-paragraphs-title {
  letter-spacing: .01em;
  border-bottom: 10px solid #db7728;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
}

.paragraphs-item-image-and-text .field-name-field-paragraphs-content {
  letter-spacing: .01em;
  font-family: DM Sans;
  font-size: 22px;
  line-height: 1.45;
}

.paragraphs-item-slide {
  color: #000;
}

.paragraphs-item-slide .content {
  max-width: 1130px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 30px 0;
  display: flex;
}

@media (min-width: 781px) {
  .paragraphs-item-slide .content {
    max-width: 1170px;
    flex-direction: row;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1300px) {
  .paragraphs-item-slide .content {
    max-width: 1130px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.paragraphs-item-slide .group-left-column {
  margin-bottom: 20px;
}

@media (min-width: 781px) {
  .paragraphs-item-slide .group-left-column {
    margin-right: 100px;
  }
}

.paragraphs-item-slide .field-name-field-paragraphs-content {
  letter-spacing: .01em;
  background-image: url("quote-mark.0cac25c5.svg");
  background-repeat: no-repeat;
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 120px;
  font-family: DM Sans;
  font-size: 18px;
  line-height: 1.45;
}

@media (min-width: 781px) {
  .paragraphs-item-slide .field-name-field-paragraphs-content {
    font-size: 22px;
  }
}

.paragraphs-item-slide .field-name-field-attribution-line-1, .paragraphs-item-slide .field-name-field-attribution-line-2 {
  padding-left: 30px;
  font-family: DM Sans;
  font-size: 18px;
  line-height: 1.5;
  position: relative;
}

.paragraphs-item-slide .field-name-field-attribution-line-1:before {
  content: "";
  width: 23px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 11px;
  left: 0;
}

.paragraphs-item-slide .field-name-field-paragraphs-image {
  max-width: 350px;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 30px;
}

@media (min-width: 781px) {
  .paragraphs-item-slide .field-name-field-paragraphs-image {
    max-width: auto;
    width: auto;
    flex: 0 0 36%;
    margin: 0;
    padding-bottom: 0;
  }
}

.paragraphs-item-slider {
  position: relative;
}

.paragraphs-item-slider:before {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #f9d360;
  border-radius: 0 100px 0 0;
  position: absolute;
}

@media (min-width: 781px) {
  .paragraphs-item-slider:before {
    width: calc(50% + 350px);
    border-radius: 0 200px 0 0;
  }
}

.paragraphs-item-slider .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.paragraphs-item-slider .slick-prev, .paragraphs-item-slider .slick-next {
  height: 40px;
  width: 30px;
  z-index: 1;
}

.paragraphs-item-slider .slick-prev:before, .paragraphs-item-slider .slick-next:before {
  content: "";
  height: 100%;
  width: 100%;
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

@media (min-width: 1300px) {
  .paragraphs-item-slider .slick-prev, .paragraphs-item-slider .slick-next {
    height: 80px;
    width: 66px;
  }
}

.paragraphs-item-slider .slick-prev {
  left: -50px;
}

.paragraphs-item-slider .slick-prev:before {
  background-image: url("slider-arrow-left.b9a98830.svg");
}

@media (min-width: 781px) {
  .paragraphs-item-slider .slick-prev {
    left: 10px;
  }
}

@media (min-width: 1300px) {
  .paragraphs-item-slider .slick-prev {
    left: 30px;
  }
}

@media (min-width: 1440px) {
  .paragraphs-item-slider .slick-prev {
    left: calc(50% - 650px);
  }
}

.paragraphs-item-slider .slick-next {
  right: -50px;
}

.paragraphs-item-slider .slick-next:before {
  background-image: url("slider-arrow-right.5da81e64.svg");
}

@media (min-width: 781px) {
  .paragraphs-item-slider .slick-next {
    right: 10px;
  }
}

@media (min-width: 1300px) {
  .paragraphs-item-slider .slick-next {
    right: 30px;
  }
}

@media (min-width: 1440px) {
  .paragraphs-item-slider .slick-next {
    right: calc(50% - 650px);
  }
}

.paragraphs-item-slider .slick-dots {
  height: 50px;
  max-width: 1130px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  position: static;
}

@media (min-width: 781px) {
  .paragraphs-item-slider .slick-dots {
    max-width: 1170px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1300px) {
  .paragraphs-item-slider .slick-dots {
    max-width: 1130px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.paragraphs-item-slider .slick-dots li {
  margin-left: 0;
  margin-right: 6px;
}

.paragraphs-item-slider .slick-dots li.slick-active button:before, .paragraphs-item-slider .slick-dots li:hover button:before {
  background-color: #0c8643;
}

.paragraphs-item-slider .slick-dots button:before {
  content: "";
  height: 19px;
  width: 19px;
  opacity: 1;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  display: block;
}

.paragraphs-item-text-and-cta {
  color: #000;
  background-color: #f3efe3;
  background-image: url("background-pattern.77c792d6.png");
  background-position: calc(50% - 410px);
  background-repeat: no-repeat;
  background-size: auto calc(100% + 1px);
}

.paragraphs-item-text-and-cta > .content {
  max-width: 1260px;
  margin: 0 auto;
  padding: 100px 30px;
}

.paragraphs-item-text-and-cta > .content * {
  max-width: 515px;
  margin-left: auto;
}

.paragraphs-item-text-and-cta .field-name-field-paragraphs-title {
  font-family: Montserrat;
  font-size: 24px;
  line-height: 1.3;
}

.paragraphs-item-text-and-cta .field-name-field-paragraphs-content {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: DM Sans;
  font-size: 18px;
  line-height: 1.5;
}

.paragraphs-item-text-and-cta .field-name-field-paragraphs-cta a {
  color: #fff;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: #0c8643;
  background-image: url("icon-button-arrow.56468076.svg");
  background-position: calc(100% - 30px);
  background-repeat: no-repeat;
  border-radius: 30px;
  padding: 18px 50px 18px 30px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.paragraphs-item-text-and-cta .field-name-field-paragraphs-cta a:hover {
  background-color: #ab1f24;
  text-decoration: none;
}

.paragraphs-item-three-column-lists {
  color: #fff;
  background-color: #0c8643;
  background-image: url("decoration-vertical.1fba82d1.svg"), url("decoration-stacked-arrows.0bb67475.svg");
  background-position: calc(50% - 650px) 70px, calc(50% + 650px) calc(100% - 22px);
  background-repeat: no-repeat;
}

.paragraphs-item-three-column-lists > .content {
  max-width: 1260px;
  flex-direction: column;
  margin: 0 auto;
  padding: 100px 30px;
  display: flex;
}

@media (min-width: 781px) {
  .paragraphs-item-three-column-lists > .content {
    flex-direction: row;
  }
}

.paragraphs-item-three-column-lists .field-group-div {
  flex: 1 1 0;
}

.paragraphs-item-three-column-lists .field-group-div:not(:last-child) {
  margin-bottom: 50px;
}

@media (min-width: 781px) {
  .paragraphs-item-three-column-lists .field-group-div:not(:last-child) {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.paragraphs-item-three-column-lists div[class*="-column-title"] {
  letter-spacing: .01em;
  border-bottom: 3px solid #fff;
  margin-bottom: 30px;
  padding-bottom: 15px;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
}

.paragraphs-item-three-column-lists h5 {
  color: #fff;
  text-transform: none;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
}

.paragraphs-item-three-column-lists p {
  color: #fff;
  margin-bottom: 10px;
  font-family: DM Sans;
  font-size: 16px;
}

.paragraphs-item-three-column-lists p.subtitle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

.paragraphs-item-three-column-lists a {
  color: #fff;
  text-decoration: none;
}

.paragraphs-item-three-column-lists a:hover {
  text-decoration: underline;
}

.paragraphs-item-three-column-lists a.decorative-arrow {
  text-transform: uppercase;
  align-items: center;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.paragraphs-item-three-column-lists a.decorative-arrow:after {
  content: "";
  height: 12px;
  width: 12px;
  background-image: url("icon-button-arrow.56468076.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin-left: 10px;
  display: inline-block;
}

.paragraphs-item-three-column-lists hr {
  background-image: linear-gradient(#f9d360, #f9d360);
}

footer {
  padding-top: 50px;
  padding-bottom: 50px;
}

footer .container {
  width: 100%;
  max-width: 1260px;
  padding-left: 30px;
  padding-right: 30px;
}

footer .col-md-2 {
  width: auto;
}

footer h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

footer a.footer-contact {
  color: #0c8643;
  margin-bottom: 15px;
  font-family: DM Sans;
  text-decoration: underline;
  display: block;
}

footer a.footer-contact:hover {
  color: #ab1f24;
}

footer .social-icons li {
  box-shadow: none;
  margin-right: 30px;
}

footer .social-icons li a {
  background-color: #000;
}

footer .social-icons li a .fa:before {
  color: #fff;
}

footer .social-icons li:hover.social-icons-facebook a {
  background-color: #3b5998;
}

footer .social-icons li:hover.social-icons-twitter a {
  background-color: #1da1f2;
}

footer .social-icons li:hover.social-icons-instagram a {
  background-color: #8a3ab9;
}

footer .col-md-5 {
  width: auto;
  float: left;
  background-image: url("seal.9eabf483.png");
  background-repeat: no-repeat;
  margin-top: 30px;
  padding-left: 110px;
}

@media (min-width: 992px) {
  footer .col-md-5 {
    float: right;
    margin-top: 0;
  }
}

footer .hbu.top {
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 1.5;
}

footer .hbu.bottom {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
}

footer .hbu a {
  color: #000;
}

.footer-copyright .container > .row {
  margin-left: 0;
  margin-right: 0;
}

.region-footer-bottom {
  margin-bottom: 0;
}

.region-footer-bottom p {
  color: #000;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: DM Sans;
  font-size: 12px;
  line-height: 1.5;
}

.region-footer-bottom p a {
  color: #000;
}

#block-blockpop-popup {
  bottom: unset;
  max-height: 98%;
  overflow: visible;
}

#block-blockpop-popup img {
  max-height: 96dvh;
}

/*# sourceMappingURL=style.css.map */
