.paragraphs-item-three-column-lists {
  background-color: $color-green;
  background-image: url('../images/decoration-vertical.svg'), url('../images/decoration-stacked-arrows.svg');
  background-repeat: no-repeat;
  background-position: calc(50% - 650px) 70px, calc(50% + 650px) calc(100% - 22px);
  color: $color-white;

  > .content {
    display: flex;
    max-width: calc($content-max-width + (2 * $site-padding));
    margin: 0 auto;
    padding: 100px $site-padding;
    flex-direction: column;

    @include breakpoint($screen-tablet) {
      flex-direction: row;
    }
  }

  .field-group-div {
    flex: 1 1 0px;

    &:not(:last-child) {
      margin-bottom: 50px;

      @include breakpoint($screen-tablet) {
        margin-right: $site-padding;
        margin-bottom: 0px;
      }
    }
  }

  div[class*="-column-title"] {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 3px solid $color-white;
    font-family: $font-montserrat;
    font-size: 36px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.01em;
    line-height: 1.3;
  }

  h5 {
    margin-bottom: 10px;
    color: $color-white;
    font-family: $font-montserrat;
    font-size: 20px;
    font-weight: $font-weight-normal;
    line-height: 1.3;
    text-transform: none;
  }

  p {
    margin-bottom: 10px;
    color: $color-white;
    font-family: $font-dm-sans;
    font-size: 16px;
    &.subtitle {
      font-size: 14px;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }

  a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  a.decorative-arrow {
    display: flex;
    margin-top: 30px;
    align-items: center;
    font-size: 14px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    &:after {
      content: '';
      display: inline-block;
      margin-left: 10px;
      height: 12px;
      width: 12px;
      background-image: url('../images/icon-button-arrow.svg');
      background-repeat: no-repeat;
      background-size: auto 100%;
    }
  }

  hr {
    background-image: linear-gradient($color-yellow, $color-yellow);
  }
}
