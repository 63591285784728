.paragraphs-item-hero {
  position: relative;
  border-radius: 0 100px 0 0;
  overflow: hidden;

  @include breakpoint($screen-tablet) {
    border-radius: 0 200px 0 0;
  }

  .field-name-field-hero-image {
    &:after {
      @include breakpoint($screen-tablet) {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-image: url('../images/decoration-horizontal.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 60px) calc(100% - 30px);
      }
    }
  }

  .hero-bg-image {
    height: 250px;
    background-position-x: 100%;

    @include breakpoint($screen-tablet) {
      height: 650px;
      background-position-x: center;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-yellow;
      z-index: -1;

      @include breakpoint($screen-tablet) {
        background-color: transparent;
        background-image: linear-gradient(45deg, $color-black, transparent);
        z-index: auto;
      }

      @include breakpoint($screen-desktop) {
        content: none;
      }
    }
  }

  .group-content {
    max-width: calc($content-max-width + 240px);
    margin: 0 auto;
    padding: $site-padding;
    top: 30%;
    left: 0;
    right: 0;

    @include breakpoint($screen-tablet) {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 70px;
    }
  }

  .field-name-field-hero-title {
    max-width: 100%;
    margin-bottom: 30px;
    color: $color-black;
    font-family: $font-montserrat;
    font-size: 30px;
    line-height: 1.3;

    @include breakpoint($screen-mobile) {
      font-size: 48px;
    }

    @include breakpoint($screen-tablet) {
      max-width: 690px;
      color: $color-white;
    }
  }

  .field-name-field-hero-cta a {
    @include button;
    display: inline-block;
  }
}
