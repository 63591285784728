.paragraphs-item-slide {
  color: $color-black;

  .content {
    display: flex;
    max-width: calc($content-max-width - 70px);
    margin-left: auto;
    margin-right: auto;
    padding: 50px $site-padding 0px;
    flex-direction: column;

    @include breakpoint($screen-tablet) {
      max-width: calc($content-max-width - 30px);
      padding-left: 50px;
      padding-right: 50px;
      flex-direction: row;
    }

    @include breakpoint($screen-large) {
      max-width: calc($content-max-width - 70px);
      padding-left: $site-padding;
      padding-right: $site-padding;
    }
  }

  .group-left-column {
    margin-bottom: 20px;

    @include breakpoint($screen-tablet) {
      margin-right: 100px;
    }
  }

  .field-name-field-paragraphs-content {
    margin-top: 15px;
    margin-bottom: 20px;
    padding-top: 120px;
    background-image: url('../images/quote-mark.svg');
    background-repeat: no-repeat;
    font-family: $font-dm-sans;
    font-size: 18px;
    letter-spacing: 0.01em;
    line-height: 1.45;

    @include breakpoint($screen-tablet) {
      font-size: 22px;
    }
  }

  .field-name-field-attribution-line-1,
  .field-name-field-attribution-line-2 {
    position: relative;
    padding-left: 30px;
    font-family: $font-dm-sans;
    font-size: 18px;
    line-height: 1.5;
  }
  .field-name-field-attribution-line-1:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 1px;
    top: 11px;
    left: 0px;
    background-color: $color-black;
  }

  .field-name-field-paragraphs-image {
    max-width: 350px;
    width: 60%;
    margin: 0 auto;
    padding-bottom: $site-padding;

    @include breakpoint($screen-tablet) {
      max-width: auto;
      width: auto;
      margin: 0;
      padding-bottom: 0;
      flex: 0 0 36%;
    }
  }
}
