#logo {
  display: block;
  img {
    display: block;
    height: 54px !important;
    width: auto !important;
    top: auto !important;
  }
}

.sticky-header-active #header .header-body {
  top: 0px !important;
}
