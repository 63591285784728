@mixin button {
  padding: 18px 50px 18px 30px;
  background-color: $color-green;
  background-image: url('../images/icon-button-arrow.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 30px);
  border-radius: 30px;
  color: $color-white;
  font-family: $font-montserrat;
  font-weight: $font-weight-bold;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
  &:hover {
    background-color: $color-red;
    text-decoration: none;
  }
}
