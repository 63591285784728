$screen-mobile: 545px;
$screen-tablet: 781px;
$screen-tablet-wide: 921px;
$screen-desktop: 1200px;
$screen-large: 1300px;
$screen-xl: 1440px;

$content-max-width: 1200px;
$site-padding: 30px;

$color-yellow: #F9D360;
$color-green: #0C8643;
$color-red: #AB1F24;
$color-orange: #DB7728;
$color-milk: #F3EFE3;
$color-butter: #FFEBB0;
$color-white: #ffffff;
$color-black: #000000;

$font-montserrat: "Montserrat";
$font-dm-sans: "DM Sans";

$font-weight-normal: 400;
$font-weight-bold: 700;
