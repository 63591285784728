.paragraphs-item-slider {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $color-yellow;
    border-radius: 0 100px 0 0;
    z-index: -1;

    @include breakpoint($screen-tablet) {
      width: calc(50% + 350px);
      border-radius: 0 200px 0 0;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-prev,
  .slick-next {
    height: 40px;
    width: 30px;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }

    @include breakpoint($screen-large) {
      height: 80px;
      width: 66px;
    }
  }

  .slick-prev {
    left: -50px;
    &:before {
      background-image: url('../images/slider-arrow-left.svg');
    }

    @include breakpoint($screen-tablet) {
      left: 10px;
    }

    @include breakpoint($screen-large) {
      left: 30px;
    }

    @include breakpoint($screen-xl) {
      left: calc(50% - 650px);
    }
  }

  .slick-next {
    right: -50px;
    &:before {
      background-image: url('../images/slider-arrow-right.svg');
    }

    @include breakpoint($screen-tablet) {
      right: 10px;
    }

    @include breakpoint($screen-large) {
      right: 30px;
    }

    @include breakpoint($screen-xl) {
      right: calc(50% - 650px);
    }
  }

  .slick-dots {
    position: static;
    height: 50px;
    max-width: calc($content-max-width - 70px);
    margin-left: auto;
    margin-right: auto;
    padding-left: $site-padding;
    padding-right: $site-padding;
    text-align: left;

    @include breakpoint($screen-tablet) {
      max-width: calc($content-max-width - 30px);
      padding-left: 50px;
      padding-right: 50px;
    }

    @include breakpoint($screen-large) {
      max-width: calc($content-max-width - 70px);
      padding-left: $site-padding;
      padding-right: $site-padding;
    }

    li {
      margin-left: 0;
      margin-right: 6px;

      &.slick-active,
      &:hover {
        button:before {
          background-color: $color-green;
        }
      }
    }

    button:before {
      content: '';
      display: block;
      height: 19px;
      width: 19px;
      background-color: $color-white;
      border: 2px solid $color-black;
      border-radius: 10px;
      opacity: 1;
    }
  }
}
